import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import ReactQueryKeys from '../keys';
import { apiV1, getAll } from '../utils';

import type { Buyer } from '../../types/buyer.type';

export const getBuyers = getAll<Buyer>(apiV1, '/buyers/');

const useGetBuyers = (page = 1, limit = 10, filters = {}) => {
  return useQuery(
    [ReactQueryKeys.GET_BUYERS, page, limit, filters],
    () => getBuyers(page, limit, filters),
    {
      keepPreviousData: true,
      onError: (error: AxiosError) => {
        toast.error(error.response?.data?.message);
      },
    }
  );
};

export default useGetBuyers;
