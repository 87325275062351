import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import ReactQueryKeys from '../keys';
import { apiV1, getAll } from '../utils';

import type { BuyerType } from '../../types/buyer-type.type';

export const getBuyerTypes = getAll<BuyerType>(apiV1, '/buyer-types/');

const useGetBuyerTypes = (page = 1, limit = 10, filters = {}) => {
  return useQuery(
    [ReactQueryKeys.GET_BUYER_TYPES, page, limit, filters],
    () => getBuyerTypes(page, limit, filters),
    {
      keepPreviousData: true,
      onError: (error: AxiosError) => {
        toast.error(error.response?.data?.message);
      },
    }
  );
};

export default useGetBuyerTypes;
