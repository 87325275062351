import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import ReactQueryKeys from '../keys';
import { apiV1, getAll } from '../utils';

import type { Animal } from '../../types/animal.type';

export const getAnimals = getAll<Animal>(apiV1, '/animals/');

const useGetAnimals = (page = 1, limit = 10, filters = {}) => {
  return useQuery(
    [ReactQueryKeys.GET_ANIMALS, page, limit, filters],
    ({ signal }) => getAnimals(page, limit, filters, signal),
    {
      keepPreviousData: true,
      onError: (error: AxiosError) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );
};

export default useGetAnimals;
