/* eslint-disable @typescript-eslint/no-explicit-any */

const debounce = (cb: (...args: any[]) => void, delay: number) => {
  let timeout: NodeJS.Timeout | undefined;

  return (...args: any[]) => {
    if (timeout) clearTimeout(timeout);

    timeout = setTimeout(() => cb(...args), delay);
  };
};

export default debounce;
