import { FC } from 'react';

import { InputErrorMessageText } from './input-error-message.styles';

interface IInputErrorMessage {
  message: string;
}

const InputErrorMessage: FC<IInputErrorMessage> = ({ message }) => {
  return <InputErrorMessageText role="alert">{message}</InputErrorMessageText>;
};

export default InputErrorMessage;
