import getSelectOptions from './getSelectOptions';

import { getAnimals } from '~/react-query/queries/useGetAnimals';
import { getInventoryItems } from '~/react-query/queries/useGetInventoryItems';
import { getDiseases } from '~/react-query/queries/useGetDiseases';
import { getTestingLabs } from '~/react-query/queries/useGetTestingLabs';
import { getInventoryTypes } from '~/react-query/queries/useGetInventoryTypes';
import { getBuyers } from '~/react-query/queries/useGetBuyers';
import { getBuyerTypes } from '~/react-query/queries/useGetBuyerTypes';
import { getAllPastures } from '~/react-query/queries/useGetPastures';
import { getBreedingTypes } from '~/react-query/queries/useGetBreedingTypes';
import { getOrganizations } from '~/react-query/queries/useGetOrganizations';
import { getMetrics } from '~/react-query/queries/useGetMetrics';
import { getMeasures } from '~/react-query/queries/useGetMeasures';
import { getTraits } from '~/react-query/queries/useGetTraits';
import { getBreeds } from '~/react-query/queries/useGetBreeds';

import type { InventoryItem } from '~/types/inventory-item.type';

export const loadAnimals = async (
  filters = {},
  callback: (options: unknown) => void
) => {
  const data = await getAnimals(1, 1000, filters);

  callback(getSelectOptions(data?.results) ?? []);
};

export const loadInventoryItems = async (
  filters = {},
  callback: (options: unknown) => void,
  filterFunc?: (item: InventoryItem) => boolean
) => {
  const data = await getInventoryItems(1, 1000, filters);

  if (filterFunc) {
    data.results = data?.results?.filter(filterFunc);
  }

  callback(getSelectOptions(data?.results) ?? []);
};

export const loadDiseases = async (
  filters = {},
  callback: (options: unknown) => void
) => {
  const data = await getDiseases(1, 1000, filters);

  callback(getSelectOptions(data?.results) ?? []);
};

export const loadTestingLabs = async (
  filters = {},
  callback: (options: unknown) => void
) => {
  const data = await getTestingLabs(1, 1000, filters);

  callback(getSelectOptions(data?.results) ?? []);
};

export const loadInventoryTypes = async (
  filters = {},
  callback: (options: unknown) => void
) => {
  const data = await getInventoryTypes(1, 1000, filters);

  callback(getSelectOptions(data?.results) ?? []);
};

export const loadBuyers = async (
  filters = {},
  callback: (options: unknown) => void
) => {
  const data = await getBuyers(1, 1000, filters);

  callback(getSelectOptions(data?.results) ?? []);
};

export const loadBuyerTypes = async (
  filters = {},
  callback: (options: unknown) => void
) => {
  const data = await getBuyerTypes(1, 1000, filters);

  callback(getSelectOptions(data?.results) ?? []);
};

export const loadPastures = async (
  filters = {},
  callback: (options: unknown) => void
) => {
  const data = await getAllPastures(1, 1000, filters);

  callback(getSelectOptions(data?.results) ?? []);
};

export const loadBreedingTypes = async (
  filters = {},
  callback: (options: unknown) => void
) => {
  const data = await getBreedingTypes(1, 1000, filters);

  callback(getSelectOptions(data?.results) ?? []);
};

export const loadOrganizations = async (
  filters = {},
  callback: (options: unknown) => void
) => {
  const data = await getOrganizations(1, 1000, filters);

  callback(getSelectOptions(data?.results) ?? []);
};

export const loadMetrics = async (
  filters = {},
  callback: (options: unknown) => void
) => {
  const data = await getMetrics(1, 1000, filters);

  callback(getSelectOptions(data?.results) ?? []);
};

export const loadMeasures = async (
  filters = {},
  callback: (options: unknown) => void
) => {
  const data = await getMeasures(1, 1000, filters);

  callback(getSelectOptions(data?.results) ?? []);
};

export const loadTraits = async (
  filters = {},
  callback: (options: unknown) => void
) => {
  const data = await getTraits(1, 1000, filters);

  callback(getSelectOptions(data?.results) ?? []);
};

export const loadBreeds = async (
  filters = {},
  callback: (options: unknown) => void
) => {
  const data = await getBreeds(1, 1000, filters);

  callback(getSelectOptions(data?.results) ?? []);
};
