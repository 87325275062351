/* eslint-disable @typescript-eslint/no-explicit-any */

const getSelectOptions = (
  array?: Record<string, any>[],
  valueProperty = 'id',
  labelProperty = 'name'
) => {
  if (!array) return [];

  return array.map((el) => ({
    label: el?.[labelProperty],
    value: String(el?.[valueProperty]),
  }));
};

export default getSelectOptions;
