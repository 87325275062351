import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import ReactQueryKeys from '../keys';
import { apiV1, getAll } from '../utils';

import useStore from '../../store';

import type { TestingLab } from '../../types/testing-lab.type';

export const getTestingLabs = getAll<TestingLab>(apiV1, '/testing-labs/');

const useGetTestingLabs = (page = 1, limit = 10, filters = {}) => {
  const currentUser = useStore((state) => state.currentUser);

  return useQuery(
    [ReactQueryKeys.GET_TESTING_LABS, currentUser?.pk, page, limit, filters],
    () => getTestingLabs(page, limit, filters),
    {
      keepPreviousData: true,
      onError: (error: AxiosError) => {
        toast.error(error.response?.data?.message);
      },
    }
  );
};

export default useGetTestingLabs;
