import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import ReactQueryKeys from '../keys';
import { apiV1, getAll } from '../utils';

import useStore from '../../store';

import type { BreedingType } from '../../types/breeding-type.type';

export const getBreedingTypes = getAll<BreedingType>(apiV1, '/breeding-types/');

const useGetBreedingTypes = (page = 1, limit = 10, filters = {}) => {
  const currentUser = useStore((state) => state.currentUser);

  return useQuery(
    [ReactQueryKeys.GET_BREEDING_TYPES, currentUser?.pk, page, limit, filters],
    () => getBreedingTypes(page, limit, filters),
    {
      keepPreviousData: true,
      onError: (error: AxiosError) => {
        toast.error(error.response?.data?.message);
      },
    }
  );
};

export default useGetBreedingTypes;
