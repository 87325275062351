/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import { RefCallBack } from 'react-hook-form';
import ReactSelect, { StylesConfig, Props } from 'react-select';
import styled, { StyledComponentPropsWithRef } from 'styled-components';

import { colors } from '~/lib/colors';

const SelectContainer = styled(ReactSelect)<Omit<ICustomSelectProps, 'id'>>`
  width: 100%;
`;

import SvgIcon from '../svg-icon/svg-icon.component';
import InputErrorMessage from '../input-error-message/input-error-message.component';

export type SelectOptionType = {
  value: string;
  label: string;
};

export interface ICustomSelectProps
  extends StyledComponentPropsWithRef<any>,
    Props {
  id: string;
  options: SelectOptionType[];
  inputRef: RefCallBack;
  error: Record<any, any> | undefined;
}

export const customStyles: StylesConfig = {
  control: (provided, state: any) => ({
    ...provided,
    boxShadow: 'none',
    width: '100%',
    backgroundColor: 'transparent',
    borderRadius: '5px',
    borderColor: state.selectProps.isError
      ? colors.error
      : state.isFocused
      ? 'rgb(49, 130, 206)'
      : 'rgba(0, 0, 0, 0.5)',
    '&:hover': 'none',
    transition: 'border-color 0.2s',
  }),
  menu: (provided) => ({
    ...provided,
    textAlign: 'left',
    zIndex: 8,
  }),
  input: (provided) => ({
    ...provided,
    paddingBottom: 0,
    paddingTop: 0,
    margin: 0,
    left: 0,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding:
      state.selectProps.isMulti && state.hasValue
        ? '7.31px 1.6rem'
        : '1.05rem 1.6rem',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: colors.gray,
    fontWeight: 400,
    opacity: 0.6,
    marginLeft: 0,
    marginRight: 0,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  indicatorsContainer: (provided) => ({ ...provided, paddingRight: '1.6rem' }),
  multiValue: (provided) => ({
    ...provided,
    margin: 0,
    marginRight: '2px',
    marginTop: '2px',
    marginBottom: '2px',
  }),
};

export const DropdownIndicator = () => {
  return <SvgIcon iconName="chevron-down" />;
};

const Select: FC<ICustomSelectProps> = ({
  id,
  options,
  inputRef,
  error,
  ...otherProps
}) => {
  return (
    <>
      <SelectContainer
        ref={inputRef}
        components={{ DropdownIndicator }}
        styles={customStyles}
        options={options}
        placeholder="Select one..."
        isSearchable={false}
        isClearable={false}
        inputId={id}
        instanceId={id}
        isError={Boolean(error)}
        {...otherProps}
      />

      {error?.message && (
        <InputErrorMessage message={error?.message as string} />
      )}
    </>
  );
};

Select.displayName = 'Select';

export default Select;
