import styled from 'styled-components';

import { colors } from '~/lib/colors';

export const InputErrorMessageText = styled.p`
  position: absolute;
  top: 100%;
  left: 0;
  color: ${colors.error};
  font-size: 1.2rem;
`;
