import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import ReactQueryKeys from '../keys';
import { apiV1, getAll } from '../utils';

import type { Organization } from '../../types/organization.type';

export const getOrganizations = getAll<Organization>(apiV1, '/organizations/');

const useGetOrganizations = (page = 1, limit = 10, filters = {}) => {
  return useQuery(
    [ReactQueryKeys.GET_ORGANIZATIONS, page, limit, filters],
    () => getOrganizations(page, limit, filters),
    {
      keepPreviousData: true,
      onError: (error: AxiosError) => {
        toast.error(error.response?.data?.message);
      },
    }
  );
};

export default useGetOrganizations;
