import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import ReactQueryKeys from '../keys';
import { apiV1, getAll } from '../utils';

import useStore from '../../store';

import type { InventoryType } from '../../types/inventory-type.type';

export const getInventoryTypes = getAll<InventoryType>(
  apiV1,
  '/inventory-types/'
);

const useGetInventoryTypes = (page = 1, limit = 10, filters = {}) => {
  const currentUser = useStore((state) => state.currentUser);

  return useQuery(
    [ReactQueryKeys.GET_INVENTORY_TYPES, currentUser?.pk, page, limit, filters],
    () => getInventoryTypes(page, limit, filters),
    {
      keepPreviousData: true,
      onError: (error: AxiosError) => {
        toast.error(error.response?.data?.message);
      },
    }
  );
};

export default useGetInventoryTypes;
